import { PageProps } from "gatsby"
import React, { useEffect } from "react"
import Layout from "../components/Layout"

const PortaalPage: React.FC<PageProps> = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      /* eslint-disable no-unused-vars */
      PageLoad({})
    }
  }, []);

  return (
    <Layout title="Home">
      <iframe
        id="ImPatientPortal"
        name="ImPatientPortal"
        width="100%"
        frameBorder="0"
        allow="clipboard-write"
      ></iframe>
    </Layout>
  )
}

export default PortaalPage
